import React, { ReactElement } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Container from '@objects/container'
import Headline from '@objects/headline'
import Icon from '@objects/icon'
import { Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    pageRoot: {
      marginTop: theme.spacing(15),
    },
    grid: {
      marginBottom: theme.spacing(15),
    },
  })
)

export default function RootIndex(): ReactElement {
  const classes = useStyles()

  return (
    <Container className={classes.pageRoot}>
      <Headline level={1}>Icons</Headline>

      <Grid className={classes.grid} container spacing={4}>
        {[
          'ArrowDown',
          'ArrowUp',
          'Basket',
          'ChevronDown',
          'ChevronLeft',
          'ChevronRight',
          'ChevronUp',
          'Close',
          'Description',
          'Download',
          'DownloadColored',
          'DownloadMaterial',
          'Duration',
          'EasyLang',
          'Facebook',
          'Home',
          'Layers',
          'Mail',
          'OpenInNew',
          'Phone',
          'Print',
          'Scale',
          'Search',
          'SearchEmpty',
          'Speech',
          'Twitter',
          'YouTube',
          'ChartCheck',
          'ChartNoCheck',
          'BarrierFree',
          'Megaphone',
          'About',
          'Basket',
          'Gebaerdensprache',
        ].map((icon) => {
          return (
            <Grid key={icon} item xs={4} sm={3} md={2}>
              <Icon name={icon} />
              <br />
              {icon}
            </Grid>
          )
        })}
      </Grid>

      <Headline level={2}>Illus</Headline>
      <Grid className={classes.grid} container spacing={4}>
        {[
          'IlluBeginner',
          'IlluBike',
          'IlluCar',
          'IlluChildren',
          'IlluCommercial',
          'IlluMotorcycle',
          'IlluPedestrian',
          'IlluSeniors',
        ].map((icon) => {
          return (
            <Grid key={icon} item xs={4} sm={3} md={2}>
              <Icon name={icon} />
              <br />
              {icon}
            </Grid>
          )
        })}
      </Grid>

      <Headline level={2}>Size</Headline>
      <Grid className={classes.grid} container spacing={4}>
        {['inherit', 'small', 'default', 'large'].map((size) => {
          return (
            <Grid key={size} item xs={4} sm={3} md={2}>
              <Icon name="Home" size={size} />
              <br />
              {size}
            </Grid>
          )
        })}
      </Grid>

      <Headline level={2}>Color</Headline>
      <Grid className={classes.grid} container spacing={4}>
        {['inherit', 'primary', 'secondary', 'action', 'error', 'disabled'].map(
          (color) => {
            return (
              <Grid key={color} item xs={4} sm={3} md={2}>
                <Icon name="Home" color={color} />
                <br />
                {color}
              </Grid>
            )
          }
        )}
      </Grid>
    </Container>
  )
}
